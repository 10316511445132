import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import "./Certificate.scss";
import { FlexiblePrice, Instruction, FixedPrice, Display } from "./components";
import { getTokenAuth, routeName } from "helpers/utils";
import { useCertificateList, useModalCallLink } from "helpers/hooks";
import { useAuth } from "modules/Auth";
import { Oval } from "react-loader-spinner";
import { toast } from "react-toastify";
import axios from "axios";
import { CertificateUsage } from "../CertificateUsage";
import { DialogContainer } from "../DialogContainer";

const router = routeName("api.certificates.all");
const token = getTokenAuth()

const findCurrentCert = (data?: any, hash?: any) => {
  const certificate = data?.find((item: any) => item.id === Number(hash.slice(1)));
  certificate.priceType = certificate?.certificates[0]?.type;
  return certificate;
};

export const Certificate: React.FC = () => {
  const [send, setSend] = useState<boolean>(false);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const { search, hash, state }: any = useLocation();
  const { certificates } = useCertificateList(router + decodeURI(search));
  const [certs, setCerts ] = useState<any>([])
  const { buyCertificate, user } = useAuth();
  const modalCall = useModalCallLink();
  
  const backgroundPath = state?.backgroundLocation?.pathname 
  const amount = user?.amount

  useEffect(()=> {
    if(backgroundPath === "/personal") {
      axios.get(routeName("api.seller.suitable-certificates", amount), {
        headers: { Authorization: `Bearer ${token}` },
      }).then(res => {
        setCerts(res?.data?.data)
      })
    } else {
      setCerts(certificates)
    }
  }, [backgroundPath, amount, certificates])

  const onToggleDialog = () => setIsOpenModal(!isOpenModal);

  const onSubmit = (id: number, value: number) => {
    const data = {
      id: id,
      price: { certificate_price: value },
    };
    setSend(true);
    buyCertificate(data, (res) => {
      if (res?.data?.message) {
        modalCall("/success");
      } else {
        setSend(false);
        toast.error(res);
      }
    });
  };

  const certificate = !!certs.length && findCurrentCert(certs, hash);
  
  return (
    <DialogContainer className="order">
      {send ? (
        <div className="form-modal__spin">
          <Oval
            color="#98092D"
            height={50}
            width={50}
            secondaryColor="#E0B5C0"
          />
        </div>
      ) : null}
    
      <div className="order__inner">
        <Display
          name={certificate?.name}
          description={certificate?.description}
          url_image={certificate?.url_image}
        />

        {certificate?.priceType === "flexible_price" ? (
          <FlexiblePrice
            certificate={certificate?.certificates[0]}
            onOpenDialog={onToggleDialog}
            handleSubmit={onSubmit}
          />
        ) : (
          <FixedPrice
            certificates={certificate?.certificates}
            onOpenDialog={onToggleDialog}
            handleSubmit={onSubmit}
          />
        )}
        <Instruction />
      </div>
      

      <CertificateUsage
        isOpen={isOpenModal}
        name={certificate?.name}
        rules={certificate?.rules}
        onClose={onToggleDialog}
      />
    </DialogContainer>
  );
};
