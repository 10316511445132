import { FC } from "react";

type TP = {
    pin: string | null
}

export const PinCode: FC<TP> = ({ pin }) => {
    return (
        <div className="ordered__item">
            {pin && (
            <div className="ordered__pin">
                Пин-код <span>{pin}</span>
            </div>
            )}
        </div>
    )
}