import { FC } from "react"

type TP = {
    price: number | null
}
export const Price: FC<TP> = ({ price }) => {
    return (
        <div className="ordered__item">
            <p>Номинал: {price} рублей</p>
        </div>
    )
}