import { FC } from "react";

type TP = {
    valid: string | null
}

export const ValidUntil: FC<TP> = ({ valid }) => {
    return (
        <div className="ordered__item">
            <p>
                <span>Срок действия: {valid}</span>
            </p>
        </div>
    )
}