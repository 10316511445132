import { FC } from "react";
import download from "../../icons/download.svg";
import { downloadFile } from "helpers/utils"

type TP = {
    name: string | null;
    files: object[];
}

export const Files: FC<TP> = ({
    name,
    files
}) => {
    const onDownload = (id: number) => {
        downloadFile(
          `/api/seller/files/${id}/download`, 
          `Сертификат_${name}`
      )
      // .then(() => toast.success("Успешно"))
      // .catch(({ response }) => toast.error(response?.data?.message))
      // .finally(()=> setLoader(false))
    }

    return (
        <div className="ordered__item">
            {!!files.length ? 
            files.map((item: any) => (
                <button
                className="ordered__pin"
                key={item?.id}
                onClick={()=> onDownload(item?.id)}
                >
                <img src={download} alt="" />
                Cкачать сертификат
                </button>
            ))
            : null}
        </div>
    )
}