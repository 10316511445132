import axios from "axios"
import { getTokenAuth } from "./localStore"
const token = getTokenAuth()

export const downloadFile = (url: string, name: string) =>
    axios({
        url,
        method: 'GET',
        responseType: 'blob',
        headers: { 
            Authorization: `Bearer ${token}`,
        },
    },
    ).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', name)
        link.click()
    })
