import Dialog from "@reach/dialog";
import { ButtonClose } from "components/Form/ButtonClose";
import { FC } from "react";
type  TP = {
    isOpen: boolean;
    name: string;
    onClose: ()=> void;
    rules: string;
}

export const CertificateUsage: FC<TP> = ({
    isOpen,
    name,
    rules,
    onClose
}) => {
    return (
        <Dialog
            isOpen={isOpen}
            aria-labelledby="label"
            className="instruction-modal"
            onDismiss={onClose}
        >
            <ButtonClose onClose={onClose}/>

            <div className="instruction-modal__wrap">
                <h3 className="instruction-modal__title">
                    Правила использования сертификата: {name}
                </h3>
                <div className="instruction-modal__text" dangerouslySetInnerHTML={{ __html: rules }} />
            </div>
        </Dialog>
    )
}