import { FC } from "react";
import clock from "../../icons/clock.svg";

type TP = {
    days: string | null;
}

export const DaysLeft: FC<TP> = ({ days }) => {
    return (
        <div className="ordered__item ordered__item_red">
            <p>Осталось {days}</p>
            <img src={clock} alt="" />
        </div>
    )
}