import { FC } from "react";
import { Files, PinCode, CardCode, DaysLeft, ValidUntil, Price, Activation } from "./ordered/";

type TP = {
    certificate: {
        certificate: {
            name: string | null
        }
        received_at: string | null;
        valid_until: string | null;
        price: number | null;
        days_left: string | null;
        code: string |null;
        files: object[];
        pin: string;
    }
}

export const Oredered: FC<TP> = ({
    certificate
}) => {
    return (
        <div className="ordered">
            <Activation activation={certificate?.received_at} />

            <div className="ordered__row">
                <ValidUntil valid={certificate?.valid_until}/>
                
                <Price price={certificate?.price} />
            </div>

            {certificate?.days_left ?
                <div className="ordered__row">
                    <DaysLeft days={certificate?.days_left} />
                </div> 
                :

                <>
                    <div className="ordered__row">
                        <div className="ordered__item"></div>
                        <CardCode code={certificate?.code}/>
                    </div>

                    <div className="ordered__row">
                        <Files
                            name={certificate?.certificate?.name}
                            files={certificate?.files}
                        />
                        <PinCode pin={certificate?.pin} />
                    </div>
                </>
            }
            
        </div>
    )
}