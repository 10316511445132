import { useState } from "react";
import { useParams } from "react-router-dom";
import { useOnDimiss } from "modules/Modal";
import { Display, Instruction, Oredered } from "./components";
import { useAuth } from "modules/Auth";
import { Button } from "components/Form";

import { CertificateUsage } from "../CertificateUsage";
import { DialogContainer } from "../DialogContainer";

import "./Certificate.scss";

export const CertificateDetail: React.FC = () => {
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const { certificate_id } = useParams();
  const { user } = useAuth();
  const onDismiss = useOnDimiss();

  const currentCertificate =
    user &&
    user?.certificates.find((item: any) => item.id === Number(certificate_id));

  const onToggleDialog = () => setIsOpenModal(!isOpenModal);

  return (
    <DialogContainer className="order">
      <div className="order__inner">
          <Display
            name={currentCertificate?.certificate?.name}
            description={currentCertificate?.certificate?.description}
            url_image={currentCertificate?.certificate?.url_image}
          />
          
          <Oredered certificate={currentCertificate} />

          <Instruction />

          <div className="order__row">
            <Button className="order__rules" handleClick={onToggleDialog}>
              Правила использования
            </Button>
            <Button color="green" handleClick={onDismiss}>
              Закрыть
            </Button>
          </div>
        </div>

        <CertificateUsage
          isOpen={isOpenModal}
          name={currentCertificate?.certificate?.name}
          rules={currentCertificate?.certificate?.rules}
          onClose={onToggleDialog}
        />
    </DialogContainer>
  );
};
