import { FC } from "react";

type TP = {
    code: string | null
}

export const CardCode: FC<TP> = ({ code }) => {
    return (
        <div className="ordered__item">
            {code && <p>Код карты: {code}</p>}  
        </div>
    )
}