import axios from "axios"
import useSWR, { useSWRConfig } from "swr"
import { getTokenAuth, routeName } from "helpers/utils"

const token = getTokenAuth() 

const fetcher = (url: string) => axios.get(url, {
    headers: { Authorization: `Bearer ${token}` },
  }).then(({ data }: any) => data)

export const useGetPassport = () => {
    const { mutate } = useSWRConfig()
    const { data, error } = useSWR(routeName("api.seller.passport"), fetcher)
    const updatePassportData = () => mutate(routeName("api.seller.passport"))
    return {
        userPassport: data?.data,
        error: error,
        updatePassportData
    }
}