import { replaceDate } from "helpers/utils";
import { FC } from "react";

type TP = {
    activation: string | null
}

export const Activation: FC<TP> = ({ activation }) => {
    return activation ? (
        <div className="ordered__row">
          <div className="ordered__item">
            <p>
              Дата активации:
              <span> {replaceDate(activation)} </span>
            </p>
          </div>
        </div>
      ) : null
} 