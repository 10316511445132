import { FC } from "react";
import close from "./close.svg";
import styles from "./ButtonClose.module.scss"
type TP = {
    onClose: ()=> void; 
}

export const ButtonClose: FC<TP> = ({
    onClose
}) => {
    return (
        <button onClick={onClose} className={styles.close}>
            <img src={close} alt="" />
        </button>
    )
}