import Dialog from "@reach/dialog";
import { ButtonClose } from "components/Form/ButtonClose";
import { useOnDimiss } from "modules/Modal/useOnDimiss";
import { FC, useRef } from "react";

export const DialogContainer: FC<{ className: string }> = ({ className, children }) => {
    const refInputNominal = useRef<HTMLInputElement>(null);
    const onDismiss = useOnDimiss()
    
    return (
        <Dialog
            aria-labelledby="label"
            initialFocusRef={refInputNominal}
            onDismiss={onDismiss}
            className={className}
        >
            <ButtonClose onClose={onDismiss}/>
            {children}
        </Dialog>
    )
}