import { FC } from "react";
import { Collapsible } from "./Collapsible";

type TP = {
    name: string;
    url_image: string;
    description: string;
}

export const Display: FC<TP> = ({
    name,
    url_image,
    description,
}) => {
    return (
        <>
            <div className="order__row">
                <div className="order__block">
                    <h3 className="order__title">{name}</h3>
                    <p className="order__text">{description}</p>
                </div>
                <div className="order__img">
                    <img src={url_image} alt="" />
                </div>
            </div>
            
            <Collapsible description={description} />
        </>
    )
}