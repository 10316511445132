import { FC, useState } from "react";
import down from "../icons/down.svg";

type TP = {
    description: string;

}

export const Collapsible: FC<TP> = ({description}) => {
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const onToggle = () => setIsOpen(!isOpen)

    return (
        <p
          className={`order__text order__text_mobile ${isOpen ? "open" : ""}`}
          onClick={onToggle}
        >
          <span>{description}</span>
          <img src={down} alt="" />
        </p>
    )
}