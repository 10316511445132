import { useLayoutEffect, useState } from "react";
import { SwiperSlide } from "swiper/react";

import { SwiperSlider } from "components/SwiperSlider";
import { CertificatesItem } from "./CertificatesItem";
import { windowResize, isMobile } from "helpers/utils";
import "./Certificates.scss";

const detectAndSetPricesForItems = (data: any) => {
  const certificates = data.map((shop: any) => {
    const arr = shop.certificates
    if (arr[0].type === "flexible_price") {
      shop.MIN_PRICE = arr[0].min_price
      shop.MAX_PRICE = shop.certificates[0].max_price
    }

    if (arr[0].type === "fixed_price") {
      shop.MIN_PRICE = arr[0].price
      shop.MAX_PRICE = arr[arr.length - 1].price
    }
    return shop
  })

  return certificates
}

const certificateItem = (shop: any) => (
  <CertificatesItem
    key={shop.id}
    id={shop.id}
    name={shop.name}
    url_image={shop.url_image}
    min_price={shop.MIN_PRICE}
    max_price={shop.MAX_PRICE}
    is_active={true}
    is_fast_delivery={shop.is_fast_delivery}
  />
)

type ComponentTypes = {
  className?: string;
  data?: any;
  carousel?: boolean;
};

export const Certificates: React.FC<ComponentTypes> = ({
  className,
  data,
  carousel,
}) => {
  const [mobile, setMobile] = useState<boolean | undefined>(isMobile);
  useLayoutEffect(() => {
    windowResize(setMobile, 768);
  }, []);
  
  const detectMobile = !carousel || !mobile
  const newData = detectAndSetPricesForItems(data)
  
  return (
    <div className={`certificates ${className}`}>
      {detectMobile ? (
        <div className="certificates__wrap">
          {newData?.map((shop: any) => certificateItem(shop))}
        </div>
      ) : (
        <SwiperSlider
          slidesPerView={1}
          spaceBetween={10}
          dynamicBullets={true}
          navigation={true}
        >
          {newData.map((shop: any) => (
            <SwiperSlide>
              {certificateItem(shop)}
            </SwiperSlide>
          ))}
        </SwiperSlider>
      )}
    </div>
  );
};
