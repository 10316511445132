import { useFormContext } from "react-hook-form";

type TP = {
  id?: any;
  name?: any;
  value?: string | number;
  placeholder?: string;
  disabled?: boolean;
};

export const RadioInput: React.FC<TP> = ({
  id,
  name,
  value,
  placeholder,
  disabled,
  ...props
}) => {
  const {
    register,
    formState: { errors },
    watch
  } = useFormContext();

  const error = errors[name]?.message;
  const { gender } = watch()
  return (
    <label htmlFor={id} className={`form__radio ${error ? "error" : ""}`}>
      <input
        id={id}
        value={value}
        type = "radio"
        {...register(name)}
        disabled={disabled}
        checked={Number(gender) === value}
        hidden
      />
      <span className="form__radio_figure"></span>
      <span className="form__text">{placeholder}</span>
    </label>
  );
};
