import { downloadFile, routeName } from "helpers/utils"
import { useAuth } from "modules/Auth"
import { useState } from "react"
import { Oval } from "react-loader-spinner"
import { toast } from "react-toastify"

type TContract = {
    id: string;
    createdAt: string;
    sum: string;
}

export const Contract: React.FC<TContract> = ({ 
    id,
    createdAt,
    sum,
}) => {
    const [loader, setLoader] = useState<boolean>(false)
    const { user } = useAuth()
    
    const onDownloadContract = () => {
        setLoader(true)
        downloadFile(
            routeName("api.seller.seller.donation-contract.generate", id), 
            `Договор_${user.name}_${user.surname}_от${createdAt}_на_сумму_${sum}руб.pdf`
        )
        .then(() => toast.success("Успешно"))
        .catch(({ response }) => toast.error(response?.data?.message))
        .finally(()=> setLoader(false))
    }

    return (
        <>
            {loader ? 
                <Oval
                    color="#98092D"
                    height={10}
                    width={10}
                    secondaryColor="#ab3a57"
                /> 
                :
                <button onClick={onDownloadContract}>
                    Скачать договор
                </button>
            }
        </>
    )
}